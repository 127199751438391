const Config = {
    videoInfoUrl: process.env.PREACT_APP_FETCH_MEDIA_INFO_URL || '',
    SNOWPLOW_LIB_BASE_URL: process.env.PREACT_APP_SNOWPLOW_LIB_BASE_URL || '',
}

if (
    !Config.videoInfoUrl ||
    !Config.SNOWPLOW_LIB_BASE_URL
) {
    console.log('CONFIG Envs:', Config)
    throw new Error(
        `An environment variable is missing. Rename the '.env.sample' file to '.env.local' and change the values.`
    )
}
export default Config

import {FunctionComponent, h} from "preact";
import Helmet from "preact-helmet";
import Config from "../config/config";

const SnowplowWrapper: FunctionComponent = ({children}) => {
    const baseUrl = Config.SNOWPLOW_LIB_BASE_URL;

    return (
        <div>
            <Helmet script={[{src: `${baseUrl}/latest/vrtebahelper.min.js`, type: "text/javascript"}]} />
            {children}
            <script src={`${baseUrl}/latest/vrteba.min.js`} async={true} defer={true} />
        </div>
    )
}
export default SnowplowWrapper;

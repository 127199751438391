import { h } from 'preact';
import { Route, Router } from 'preact-router';
// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import SnowplowWrapper from "./SnowplowWrapper";

const App = () => (
	<div id="app">
        <SnowplowWrapper>
            <Router>
                <Route path="/" component={Home} />
            </Router>
        </SnowplowWrapper>
    </div>
);

export default App;
